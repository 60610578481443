
import { Component, Vue } from 'vue-property-decorator'
import { statusFilter, statusTypeFilter, parseTime } from '@/utils/filters'
import { IOfficeInfo, getOfficeTree, removeOffice } from '@/api/office'
import { transTree } from '@/utils/common'

import InputSearch from '@/components/InputSearch.vue'
import OfficeEdit from './officeEdit.vue'

@Component({
  components: { InputSearch, OfficeEdit },
  name: 'Table',
  filters: {
    statusFilter,
    statusTypeFilter,
    parseTime,
  },
})
export default class extends Vue {
  /** 数据 */
  list: IOfficeInfo[] = []
  listLoading = true

  async getList() {
    this.listLoading = true
    const tree = await getOfficeTree()
    this.list = transTree(tree, [], 'officeId')
    this.listLoading = false
  }

  /** 新增 */
  onAdd() {
    this.onEdit()
  }

  /** 编辑 */
  onEdit(item?: IOfficeInfo) {
    ;(this.$refs.officeEdit as OfficeEdit).show(item)
  }

  /** 删除 */
  onRemove(item: IOfficeInfo) {
    removeOffice(item.officeId).then(() => {
      this.$message({ message: '删除成功', type: 'success' })
      this.getList()
    })
  }

  /** 查看职位 */
  onShowJob(item: IOfficeInfo) {
    this.$router.push({ name: 'jobs', params: { officeId: item.officeId } })
  }

  /** 查看订单 */
  onShowOrder(item: IOfficeInfo) {
    this.$router.push({ name: 'order', params: { officeId: item.officeId } })
  }

  created() {
    this.getList()
  }
}
